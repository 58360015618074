<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.title">{{ t('title') }}</div>
      <div :class="$style.imageWrapper">
        <img
          v-if="resource.image_url"
          :class="$style.prizeImage"
          :src="resource.image_url.large"
          alt=""
        />
      </div>

      <div :class="$style.text">
        <span>{{ resource.name }}</span>
      </div>

      <span :class="$style.count" v-if="amount && amount > 1">
        x{{ amount }}
      </span>

      <ui-button
        @click="$emit('close')"
        :class="$style.button"
        theme="accent"
        size="small"
        fill
      >
        {{ t('buttonText') }}
      </ui-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "title": "Your reward",
    "buttonText": "Cool"
  },
  "ru": {
    "title": "твоя награда",
    "buttonText": "Круто"
  }
}
</i18n>

<script setup>
import UiButton from '~/components/ui/button'

defineProps({
  resource: {
    type: Object,
    required: true
  },
  amount: {
    type: Number,
    required: true
  }
})

defineEmits(['close'])

const { t } = useI18n()
</script>

<style lang="scss" module>
.container {
  margin: 3em;
  border-radius: 1.2em;
  background: var(--t-app-l2-bg);
  color: #fff;
  width: 32em;
  max-width: calc(100vw - 4em);
  @include down(md) {
    margin: 1.5em;
    border: 0 none;
    width: 70em;
    min-height: 100%;
    max-width: none;
  }
  @include down(sm) {
    margin: 0.6em;
    border-radius: 0.3em;
    width: calc(100vw - 3.2em);
  }
}

.content {
  padding: 4em;
  text-align: center;
  color: #ffffff;

  @include down(sm) {
    padding: 2.8em 1.6em;
  }
}

.count {
  font-weight: 500;
  font-size: 1.6em;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.5);

  @include down(sm) {
    font-size: 1.4em;
  }
}

.title {
  font-family: var(--primary-font), sans-serif;
  font-style: var(--primary-font-style);
  font-weight: 700;
  font-size: 2.4em;
  line-height: 142%;
  text-transform: uppercase;
}

.imageWrapper {
  padding-top: 75%;
  position: relative;
  margin: 2em 0 1.6em;
}

.prizeImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
}

.text {
  margin-bottom: 0.8em;
  span {
    font-family: var(--main-font), sans-serif;
    font-style: var(--primary-font-style);
    font-weight: 400;
    font-size: 1.6em;
    line-height: 150%;
  }
}

.button {
  width: 100%;
  text-transform: uppercase;
  margin-top: 3.2em;
  padding: 1.3em 1em;

  span {
    font-family: var(--main-font), sans-serif;
    font-style: var(--primary-font-style);
    font-weight: 700 !important;
    font-size: 1.6em !important;
    line-height: 120% !important;
  }
}
</style>
